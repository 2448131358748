import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SingleProduct from "../../shared-component/single-product-page"
import { productsData } from './index'

const data = {
    prodHeading:productsData[3].heading,
    prodDesc:'Optimize plant energy consumption through our AI based energy balancing algorithms.',
    prodImg: productsData[3]['prodImage'],
    subDesc:'Energy intensive manufacturing processes requires constant monitoring and analysis. The consumption and costs can inflate rapidly for critical processes, eSave can ingest multiple data points, tags, correlate it with process data and run advanced analytics algorithms for optimizing the energy parameters.',
    para:[
      {
        content:[
          {
            head:'Energy Meters Connectivity Module',
            desc:'Connect to multiple energy meters using RS485 ports, RS232 ports, MODBUS Serail/TCP-IP protocols. Get access to monitoring dashboards instantly through our plug and play module.'
          }
        ],
        img:'/static/svgs/eSave.png'
      },
      {
        content:[
          {
            head:'Energy Analytics Application',
            desc:"Plan energy saving goals and follow the directives of environment regulations. Online detection, logging and explaining unexpected energy consumption details in real time. Compare the steam consumption and production rates for multiple years and analyze how to reduce costs."
          },
        //   {
        //     head:'Plug & Play - 10 minutes only',
        //     desc:"Are you worried about interruptions in operations due to installations which may take hours or even days? Don’t worry. Ten minutes is all it takes to install the vibrational analysis kit! We designed the kit so that even your staff can install it themselves. However, if you do need us to install it for you, we are more than happy to do it."
        //   },
       ],
        img:'/static/svgs/maintenance.svg'
      }
    ]
  }

const ESave = () => (
    <Layout>
        <SEO title="eSave" />
         <SingleProduct data={data}/>
    </Layout>
)

export default ESave